body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #00506b;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.bodyDiv {
  padding: 10px 0;
  box-sizing: border-box;
  height: fit-content;
  min-height: 100vh;
  background-color: #00506b;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: #00506b;
  color: rgb(255 255 255);
  width: 80%;
  max-width: 1524px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 1200px) {
  .bodyDiv {
    height: fit-content;
    width: 100%;
  }
  body {
    height: fit-content;
    overflow-x: hidden;
  }
  .container {
    height: fit-content;
    flex-direction: column;
    margin: 20px 0;
  }
}
