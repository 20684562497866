.signFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  min-height: 600px;
  background-color: white;
  border-radius: 10px;
  padding: 20px 10px;
  color: #010039;
  margin-right: 10px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  border-radius: 10px;
  margin: 20px;
  margin-top: 0;
}

.formLogoContainer {
  height: 100px;
}

.inputContianer {
  width: 80%;
  margin-top: 16px;
}

@media screen and (max-width: 1200px) {
  .titleHolder {
    font-size: 26px;
  }

  .signFormContainer {
    margin-top: 10px;
    margin-bottom: 44px;
    max-width: 500px;
    width: 110%;
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .formContainer {
    width: 400px;
  }

  .signFormContainer {
    max-width: 400px;
  }
}
