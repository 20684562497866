.helpCardContainer {
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  width: 800px;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  color: #010039;
  overflow-y: auto;
}

.helpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: -110%;
  z-index: 2;
  transition: 400ms linear;
}

.backDrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 400ms linear;
}

.imgStepContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.imgStep {
  height: 500px;
  /* width: 80%; */
}

.verticalImgStep {
  width: 90%;
}

.note {
  color: rgb(126, 130, 133);
  font-size: 14px;
  padding-left: 20px;
}

.midInputContianer {
  width: 100%;
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .helpCardContainer {
    max-width: 80%;
  }
}
